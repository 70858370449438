<template>
  <div>
    <v-container fluid>
      <v-row align="center" justify="space-between" class="font-weight-black">
        <v-col cols="12" sm="11">
          <v-breadcrumbs :items="breadcrumbs" divider="/" v-if="!omiteBreadcrumbs">
            <template v-slot:item="props">
              <v-breadcrumbs-item
                :disabled="props.item.disabled"
                :class="{
                  'ApuracaoContrato_breadcrumbs-item': true,
                  'ApuracaoContrato_breadcrumbs-item_disabled': props.item.disabled,
                }">
                {{ props.item.text }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="1" class="text-right" v-if="exibirFiltro">
          <v-badge
              bordered
              color="orange"
              icon="mdi-lock"
              overlap
              v-show="!abrirFiltro"
              v-if="habilitarIconBadge">
              <v-icon v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
          </v-badge>
          <v-icon v-else v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
        </v-col>
      </v-row>
    </v-container>

    <apuracao-contrato-filtros
      ref="filtrosComplementares"
      v-model="abrirFiltro"
      :desabilita-aplicar="desabilitaAplicar"
      :filtros-complementares="filtrosComplementares"
      @ApuracaoContratoFiltros__AplicaFiltros="aplicarFiltros"
      @ApuracaoContratoFiltros_conteinerCarregado="abrirTooltip"
      @FiltroRapido__HabilitaIconBadge="habilitaIconBadge"
      @ApuracaoContratoFiltros__setaIdContratoTipo="setaIdContratoTipo">
    </apuracao-contrato-filtros>

    <apuracao-contrato-filtros-obrigatorios
      v-if="idContratoTipo !== null"
      :id-contrato-tipo="idContratoTipo"
      @ApuracaoContratoFiltrosObrigatorios__AplicaFiltros="aplicarFiltrosObrigatorios"
      @ApuracaoContratoFiltrosObrigatorios__FiltrosObrigatoriosPreenchidos="filtrosObrigatoriosPreenchidos"
    />
    <div class="card-title ml-12 mt-1" style="font-size: 14px; font-weight:normal;">
      {{ $t('message.filtrar_apuracao_contrato') }}
    </div>

    <apuracao-contrato-tabs
      ref="tabs"
      :totalizadores-periodos="totalizadoresPeriodos"
      :apuracoes="apuracoes"
      :total-page="totalPage"
      :desabilita-tabs="desabilitaAplicar"
      :selecionar-item="selecionarItem"
      :verificaRetornaDatasValidas="verificaRetornaDatasValidas"
      :montar-data-inicio-fim-edicao-valores="montarDataInicioFimApuracao"
      :lista-tipos-uso="listaTiposUso"
      :filtros="filtros"
      @ApuracaoContratoFiltrosObrigatorios__AplicaFiltros="aplicarFiltrosObrigatorios"
      @ApuracaoContratoTabs__IsEditandoValorBase="alterarIsEditandoValorBase">
    </apuracao-contrato-tabs>
    <v-container pt-6 fluid v-resize="onResize">
    <v-row v-if="exibirBotoesFinalizar">
      <v-footer
          v-bind="{fixed: true}"
          :style="`width: ${tamanhoRodape}px; background-color: #EEEEEE;`"
      >
        <apuracao-contrato-modal-tipo-uso-massivo
          :lista-tipos-uso="listaTiposUso"
          :apuracoes-selecionadas="apuracoesSelecionadas"
          :existe-apuracoes-selecionadas="existeApuracoesSelecionadas"
          @ApuracaoContratoModalTipoUsoMassivo__atualizaTabela="atualizaTabela">
        </apuracao-contrato-modal-tipo-uso-massivo>

        <v-spacer v-if="!xs"></v-spacer>
        <v-btn class="mr-2 my-auto"
          v-if="indFinalizarSelecionados"
          @click="finalizarSelecionados">
          {{ $t('label.finalizar_selecionados') }}
        </v-btn>
        <v-btn class="mr-15 my-auto mr-3"
          color="primary"
          v-if="apuracoes.length"
          @click="finalizarTudo">
          {{ $t('label.finalizar_tudo') }}
        </v-btn>
      </v-footer>
    </v-row>
    </v-container>

    <dialog-finalizar-apuracoes
      ref="modalFinalizarApuracoes"
      :apuracoes-inconsistentes="apuracoesInconsistentes"
      :apuracao-inconsistentes-parciais="apuracaoInconsistentesParciais"
      @agree="finalizarApuracoes">
  </dialog-finalizar-apuracoes>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import { forceLoading, forceHideLoading } from '../../common/functions/loading';
import ApuracaoContratoFiltros from './ApuracaoContratoFiltros';
import ApuracaoContratoFiltrosObrigatorios from './ApuracaoContratoFiltrosObrigatorios';
import ApuracaoContratoTabs from './ApuracaoContratoTabs';
import PlanejamentoContratoListCards from '../planejamento-contrato/list/PlanejamentoContratoListCards';
import ApuracaoContratoControleBreadcrumbs from './ApuracaoContratoControleBreadcrumbs';
import ApuracaoContratoModalTipoUsoMassivo from './modals/ApuracaoContratoModalTipoUsoMassivo';
import DialogFinalizarApuracoes from './modals/DialogFinalizarApuracoes';
import {
  buscarApuracaoFornecedorPorContrato,
  buscarTotalizadoresPeriodoApuracao,
  finalizarApuracaoLoteFornecedor,
} from '../../common/resources/apuracao/apuracao-contrato';
import {
  formatlastDayOfTheMonth,
  formatFirstDayOfTheMonth,
} from '../../common/functions/date-utils';

export default {
  name: 'ApuracaoContrato',
  components: {
    PlanejamentoContratoListCards,
    ApuracaoContratoFiltros,
    ApuracaoContratoFiltrosObrigatorios,
    ApuracaoContratoTabs,
    ApuracaoContratoModalTipoUsoMassivo,
    DialogFinalizarApuracoes,
  },
  mixins: [ApuracaoContratoControleBreadcrumbs],
  data() {
    return {
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),
      resourceApuracaoContratoParcial: this.$api.apuracaoContratoParcial(this.$resource),
      abrirFiltro: false,
      omiteBreadcrumbs: false,
      aplicarFiltroNoCarregamento: true,
      filtros: {},
      filtrosComplementares: {},
      habilitarIconBadge: false,
      showTooltip: false,
      tamanhoFiltros: 0,
      desabilitaAplicar: true,
      indFinalizarTudo: false,
      apuracoes: [],
      totalPage: 0,
      tabSelecionada: '',
      apuracoesSelecionadas: [],
      apuracoesInconsistentes: [],
      apuracaoInconsistentesParciais: [],
      listaTiposUso: [],
      totalizadoresPeriodos: {
        mensal: 0,
        semestral: 0,
        trimestral: 0,
        anual: 0,
      },
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      tamanhoTela: window.innerWidth,
      ordenacao: '',
      asc: '',
      idContratoTipo: null,
      isEditandoValorBase: false,
    };
  },
  computed: {
    ...generateComputed('APU_CONTR', [
      'canAccessPage',
    ]),
    xs() {
      return this.$vuetify.breakpoint.xs;
    },
    lg() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    tamanhoRodape() {
      return this.tamanhoTela - (this.abrirFiltro && this.lg ? this.tamanhoFiltros : 0);
    },
    existeApuracoesSelecionadas() {
      return this.apuracoesSelecionadas.filter((a) => a.selecionado).length > 0;
    },
    indFinalizarSelecionados() {
      return !this.indFinalizarTudo
        && (this.apuracoesSelecionadas.length);
    },
    exibirBotoesFinalizar() {
      return !this.isEditandoValorBase;
    },
  },
  watch: {
    exibirFiltro(val) {
      if (val) {
        setTimeout(() => this.$refs.totalizadores.aplicaFiltros(this.filtros),
          3E2);
      }
    },
  },
  methods: {
    ...mapActions('filtros', [
      'setFiltroApuracaoContrato',
      'setFiltroApuracaoContratoNivel1',
    ]),
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    onResize() {
      this.tamanhoTela = window.innerWidth;
    },
    selecionarItem(itemSelecionado, e) {
      if (e) e.stopPropagation();
      const apuracaoSelecionada = this.apuracoesSelecionadas.find((a) => a.idApuracao === itemSelecionado.idApuracao);
      if (apuracaoSelecionada && !apuracaoSelecionada.selecionado) {
        const index = this.apuracoesSelecionadas.findIndex((a) => a.idApuracao === itemSelecionado.idApuracao);
        this.apuracoesSelecionadas.splice(index, 1);
      }
      if (itemSelecionado.selecionado) {
        this.apuracoesSelecionadas.push(itemSelecionado);
      }
    },
    aplicarFiltros(filtrosLaterais, mudouFiltrosLaterais, isBuscaFiltroLateral = false) {
      this.filtrosComplementares = filtrosLaterais;
      if (isBuscaFiltroLateral) {
        this.pagination.page = 1;
      }
      if (this.tabSelecionada && mudouFiltrosLaterais) {
        const params = this.formatarFiltros(this.filtros, this.filtrosComplementares);
        params.periodo_apuracao = this.tabSelecionada.toUpperCase();
        params.asc = this.asc;
        params.colunaOrdenacao = this.ordenacao;
        this.buscarApuracoes(params);
      } else {
        this.buscarTotalizadores(this.formatarFiltros(this.filtros, this.filtrosComplementares));
      }
      if (this.$refs.tabs.tabSelecionada) {
        const nomRef = this.$refs.tabs.tabSelecionada;
        if (this.$refs.tabs.$refs[nomRef]) {
          this.$refs.tabs.$refs[nomRef].resetarExpanded();
        }
      }
      this.abrirFiltro = false;
    },
    aplicarFiltrosObrigatorios(paginationTab, tabSelecionada, mudouTab = false) {
      this.tabSelecionada = tabSelecionada;
      this.apuracoes = [];
      if (this.$refs.tabs.tabSelecionada) {
        const nomRef = this.$refs.tabs.tabSelecionada;
        if (this.$refs.tabs.$refs[nomRef]) {
          this.$refs.tabs.$refs[nomRef].resetarExpanded();
        }
      }

      if (mudouTab) {
        this.apuracoesSelecionadas = [];
      }
      if (paginationTab) {
        this.$refs.filtrosComplementares.setarIsPaginacaoAlterada(true);
        this.pagination = paginationTab;
        if (this.pagination.sortBy && this.pagination.sortBy.length) {
          const { sortBy, sortDesc } = this.pagination;
          this.asc = !sortDesc[0];
          this.ordenacao = this.formatarOrdenacaoPorValor(sortBy);
        }
      } else {
        this.pagination.page = 1;
        this.$refs.filtrosComplementares.setarIsPaginacaoAlterada(false);
      }
      if (!this.desabilitaAplicar) {
        this.$refs.filtrosComplementares.$refs.filtrosRapidos.aplicar();
      }
    },
    filtrosObrigatoriosPreenchidos(filtrosObrigatorios) {
      if (filtrosObrigatorios) {
        this.filtros = filtrosObrigatorios;
        this.desabilitaAplicar = false;
      } else if (!filtrosObrigatorios && filtrosObrigatorios !== undefined) {
        this.desabilitaAplicar = true;
      }
      this.apuracoes = [];
      this.totalizadoresPeriodos = {
        mensal: 0,
        semestral: 0,
        trimestral: 0,
        anual: 0,
      };
    },
    formatarOrdenacaoPorValor(sortBy) {
      if (sortBy && sortBy.length) {
        const valor = sortBy[0];
        if (valor === 'periodoVigencia' && this.$refs.tabs.tabSelecionada === 'tab-mensal') {
          return 'dc.data_inicio';
        }
        if (valor === 'periodoVigencia' && this.$refs.tabs.tabSelecionada !== 'tab-mensal') {
          return 'dc.datas_parciais';
        }
        if (valor === 'qtdProduto') {
          return 1;
        }
        let valorFormatado = valor.replace(/([A-Z])/g, '_$1').toLowerCase();
        valorFormatado = `dc.${valorFormatado}`;
        return valorFormatado;
      }
      return null;
    },
    abrirTooltip() {
      setTimeout(() => {
        this.showTooltip = true;
      }, 1000);
      this.tamanhoFiltros = document.getElementById('ApuracaoFiltros__filtro_rapido').offsetWidth;
    },
    buscarTotalizadores(params) {
      this.apuracoesSelecionadas = [];
      return buscarTotalizadoresPeriodoApuracao(params, this.$resource)
        .then((response) => {
          if (response.body) {
            this.totalizadoresPeriodos = this.formatarTotalizadores(response.body);
            params.periodo_apuracao = this.verificaMaiorPeriodo(this.totalizadoresPeriodos).toUpperCase();
            this.tabSelecionada = params.periodo_apuracao;
            if (params.periodo_apuracao) {
              setTimeout(() => {
                this.buscarApuracoes(params);
              }, 2E2);
            } else {
              this.apuracoes = [];
              this.totalPage = 0;
            }
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarApuracoes(params) {
      this.apuracoesSelecionadas = [];
      return buscarApuracaoFornecedorPorContrato(params, this.$resource)
        .then((response) => {
          this.asc = '';
          this.ordenacao = '';
          this.apuracoes = response.data.resposta || [];
          if (params.periodo_apuracao !== 'MENSAL' && this.apuracoes.length) {
            this.apuracoes.forEach((apuracao) => {
              const dataParcial = this.verificaRetornaDatasValidas(apuracao);
              if (dataParcial.length) {
                apuracao.dataParcialFormatada = this.formatarDataParcial(dataParcial);
              } else {
                apuracao.dataParcialFormatada = {
                  resultado: null,
                  abrirModal: null,
                };
              }
            });
          }
          this.totalPage = response.data.quantidadeRegistrosPagina;
          if (response.data.resposta) {
            this.$refs.tabs.tabSelecionada = this.buscarTabSelecionada(params.periodo_apuracao);
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    verificaMaiorPeriodo(obj) {
      let maior = -Infinity;
      let chave = '';
      Object.keys(obj).forEach((el) => {
        if (obj[el] > maior && obj[el] !== 0) {
          maior = obj[el];
          chave = el;
        }
      });
      return chave;
    },
    formatarTotalizadores(total) {
      const totalizadores = {
        mensal: 0,
        trimestral: 0,
        semestral: 0,
        anual: 0,
      };
      total.forEach((el) => {
        if (el.periodoApuracao === 'MENSAL') {
          totalizadores.mensal = el.totalPeriodo;
        }
        if (el.periodoApuracao === 'TRIMESTRAL') {
          totalizadores.trimestral = el.totalPeriodo;
        }
        if (el.periodoApuracao === 'SEMESTRAL') {
          totalizadores.semestral = el.totalPeriodo;
        }
        if (el.periodoApuracao === 'ANUAL') {
          totalizadores.anual = el.totalPeriodo;
        }
      });
      return totalizadores;
    },
    buscarTabSelecionada(periodo) {
      let tab = '';
      switch (periodo) {
        case 'MENSAL':
          tab = 'tab-mensal';
          break;
        case 'TRIMESTRAL':
          tab = 'tab-trimestral';
          break;
        case 'SEMESTRAL':
          tab = 'tab-semestral';
          break;
        case 'ANUAL':
          tab = 'tab-anual';
          break;
        default:
          tab = 'tab-mensal';
          break;
      }
      return tab;
    },
    formatarDataParcial(dataParcial) {
      const meses = [
        'JANEIRO', 'FEVEREIRO', 'MARÇO', 'ABRIL',
        'MAIO', 'JUNHO', 'JULHO', 'AGOSTO',
        'SETEMBRO', 'OUTUBRO', 'NOVEMBRO', 'DEZEMBRO',
      ];

      if (dataParcial.length === 1) {
        const [ano, mes] = dataParcial[0].split('-');
        const mesFormatado = meses[parseInt(mes, 10) - 1];
        const anoFormatado = ano.slice(-2);
        return {
          resultado: `${mesFormatado}/${anoFormatado}`,
          consecutivo: true,
          unicoMes: true,
          abrirModal: false,
        };
      }

      const mesesIncluidos = dataParcial.map((data) => {
        const [, mes] = data.split('-');
        return parseInt(mes, 10);
      });

      const anoInicial = dataParcial[0].split('-')[0];
      const anoFinal = dataParcial[dataParcial.length - 1].split('-')[0];

      const mesesContinuos = [];
      for (let i = mesesIncluidos[0]; i <= mesesIncluidos[mesesIncluidos.length - 1]; i += 1) {
        mesesContinuos.push(i);
      }

      const mesInicialFormatado = meses[mesesIncluidos[0] - 1];
      const mesFinalFormatado = meses[mesesIncluidos[mesesIncluidos.length - 1] - 1];

      let resultado = `${mesInicialFormatado}/${anoInicial.slice(-2)}`;
      let consecutivo = false;
      let unicoMes = false;
      let abrirModal = true;

      // Verificar se os meses são consecutivos
      if (anoInicial !== anoFinal || mesesIncluidos.length > 1) {
        // Se todos os meses estão incluídos consecutivamente
        if (mesesIncluidos.length === mesesContinuos.length) {
          resultado += ` a ${mesFinalFormatado}/${anoFinal.slice(-2)}`;
          consecutivo = true;
          abrirModal = false;
        } else {
          // Se os meses não são consecutivos
          for (let i = 1; i < mesesIncluidos.length - 1; i += 1) {
            const mesAtual = mesesIncluidos[i];
            const mesAtualFormatado = meses[mesAtual - 1];
            resultado += `, ${mesAtualFormatado}/${anoInicial.slice(-2)}`;
          }
          resultado += `, ${mesFinalFormatado}/${anoFinal.slice(-2)}`;
        }
      }

      // Verificar se é um único mês
      if (mesesIncluidos.length === 1) {
        unicoMes = true;
        abrirModal = false;
      }

      return {
        resultado,
        consecutivo,
        unicoMes,
        abrirModal,
      };
    },
    habilitaIconBadge(valor) {
      this.habilitarIconBadge = valor;
    },
    formatarFiltros(filtros, filtrosComplementares) {
      const filtrosFormatados = {
        ...filtrosComplementares,
        id_acao_tipo: filtros.tipoAcao,
        data_inicio_apuracao: moment(filtros.dataInicio, 'YYYY-MM').format('YYYY-MM-DD'),
        data_fim_apuracao: formatlastDayOfTheMonth(moment(filtros.dataFim, 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD'),
        ids_categoria: this.extrairIds(filtros.categoria),
        status: filtrosComplementares.apresentar_apuracoes_finalizadas ? ['AGUARDANDO_APURACAO', 'APROVADO'] : ['AGUARDANDO_APURACAO'],
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
        is_apuracao_samsung: true,
      };
      return filtrosFormatados;
    },
    extrairIds(array) {
      if (!array.length) {
        return null;
      }
      return array.map((el) => el.id);
    },
    buscarTiposUso() {
      const params = {
        indTipoUsoContrato: true,
      };
      this.workflowAcaoMagaluResource
        .buscarTiposUso(params)
        .then((res) => {
          this.listaTiposUso = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    atualizaTabela(idTipoUsoNovo) {
      const tipoUsoNovo = this.listaTiposUso.find((el) => el.id === idTipoUsoNovo);
      this.apuracoes.forEach((apuracao1) => {
        this.apuracoesSelecionadas.forEach((apuracao2) => {
          if (apuracao1.idApuracao === apuracao2.idApuracao) {
            apuracao1.tipoUsoNovo = tipoUsoNovo.nome;
            apuracao1.idTipoUsoNovo = idTipoUsoNovo;
            apuracao1.selecionado = false;
          }
        });
      });
      const nomRef = this.$refs.tabs.tabSelecionada;
      this.$refs.tabs.$refs[nomRef].reRenderizarTable();
      this.apuracoesSelecionadas = [];
    },
    async finalizarTudo() {
      this.apuracoesSelecionadas = this.apuracoes;
      await this.finalizarSelecionados();
    },
    finalizarSelecionados() {
      this.apuracoesInconsistentes = [];
      this.apuracaoInconsistentesParciais = [];

      this.apuracoesSelecionadas.forEach((a) => {
        if (this.apuracaoPossuiInconsistencia(a)) {
          this.apuracoesInconsistentes.push(a);
        }
        if (this.apuracaoPossuiInconsistenciaApuracoes(a)) {
          this.apuracaoInconsistentesParciais.push(a);
        }
      });
      this.apuracoesSelecionadas = this.apuracoesSelecionadas.filter((a) => !this.apuracaoPossuiInconsistencia(a) && a.statusApuracao !== 'APROVADO');
      this.apuracoesSelecionadas = this.apuracoesSelecionadas.filter((a) => !this.apuracaoPossuiInconsistenciaApuracoes(a));
      setTimeout(() => this.$refs.modalFinalizarApuracoes.open(this.apuracoesSelecionadas));
    },
    finalizarApuracoes() {
      forceLoading();
      if (this.tabSelecionada.toUpperCase() === 'MENSAL') {
        this.finalizarApuracoesIntegral();
      } else {
        const apuracoesFinalizadas = [];
        this.apuracoesSelecionadas
          .forEach((apuracao) => {
            apuracoesFinalizadas.push(this.prepararParcial(apuracao));
          });
        if (apuracoesFinalizadas.length) {
          this.finalizarParcial(this.removerApuracoesDuplicadas(apuracoesFinalizadas));
        } else {
          forceHideLoading();
        }
      }
    },
    removerApuracoesDuplicadas(apuracoesFinalizadas) {
      const setApuracao = new Set();
      const filterApuracao = apuracoesFinalizadas.filter((apuracao) => {
        const apuracaoDuplicas = setApuracao.has(apuracao.idApuracao);
        setApuracao.add(apuracao.idApuracao);
        return !apuracaoDuplicas;
      });
      return filterApuracao;
    },
    finalizarApuracoesIntegral() {
      const filtros = this.prepararParametros();
      finalizarApuracaoLoteFornecedor(filtros, this.$resource)
        .then(() => {
          this.aplicarFiltrosObrigatorios();
          this.$refs.modalFinalizarApuracoes.close();
          this.$toast(this.$tc('message.apuracao_finalizada_sucesso', 2));
          forceHideLoading();
        })
        .catch((err) => {
          forceHideLoading();
          this.$error(this, err);
          this.$refs.modalFinalizarApuracoes.close();
        });
    },
    finalizarParcial(apuracoesFinalizadas) {
      this.resourceApuracaoContratoParcial.gravar(apuracoesFinalizadas)
        .then(() => {
          this.aplicarFiltrosObrigatorios();
          this.$refs.modalFinalizarApuracoes.close();
          this.$toast(this.$tc('message.apuracao_finalizada_sucesso', 2));
          this.apuracoesSelecionadas = [];
          forceHideLoading();
        })
        .catch((err) => {
          forceHideLoading();
          this.$error(this, err);
        });
    },
    prepararParcial(apuracao) {
      let finalizaApuracao = false;
      let indCriaRecebimento = true;

      if (apuracao.recebimentoDefinido > 0 && (this.periodoIntegralApuracao(apuracao) || this.indTodosMesesVigentes(apuracao))) {
        finalizaApuracao = true;
      }

      if (apuracao.vlrNdGerado > 0 && apuracao.recebimentoDefinido === 0 && (this.periodoIntegralApuracao(apuracao)
        || indTodosMesesVigentes(apuracao))) {
        finalizaApuracao = true;
        indCriaRecebimento = false;
      }
      const datas = this.montarDataInicioFimApuracao(apuracao);

      if (apuracao.objValorBaseAlterado
          && (Object.values(apuracao.objValorBaseAlterado).length !== 0)) {
        apuracao.objValorBaseAlterado.dtaInicioPeriodo = datas.inicio;
        apuracao.objValorBaseAlterado.dtaFimPeriodo = datas.fim;
      }

      if (apuracao.objRecebimentoDefinidoAlterado
          && (Object.values(apuracao.objRecebimentoDefinidoAlterado).length !== 0)) {
        apuracao.objRecebimentoDefinidoAlterado.dtaInicioPeriodo = datas.inicio;
        apuracao.objRecebimentoDefinidoAlterado.dtaFimPeriodo = datas.fim;
      }

      const objArmazenamento = {
        indCriaRecebimento,
        idApuracao: apuracao.idApuracao,
        idTipoUso: apuracao.idTipoUsoNovo ? apuracao.idTipoUsoNovo : apuracao.idTipoUso,
        dataInicio: datas.inicio,
        dataFim: datas.fim,
        valorDigitado: apuracao.recebimentoDefinido,
        valorRecebimento: apuracao.recebimentoDefinido,
        valorBase: apuracao.valorBase,
        valorBaseOriginal: apuracao.valorBaseOriginal,
        indImpressaoNdComHistorico: false,
        isFinalizarApuracao: finalizaApuracao,
        verba: apuracao.verba ? apuracao.verba : apuracao.faixaAtingida,
        dataContabilizacao: apuracao.dataContabilizacao,
        condition: apuracao.condition,
        observacaoFinalizacao: apuracao.observacaoFinalizacao,
        idFornecedor: apuracao.idFornecedor,
        objValorBaseAlterado: apuracao.objValorBaseAlterado ? apuracao.objValorBaseAlterado : null,
        objRecebimentoDefinidoAlterado: apuracao.objRecebimentoDefinidoAlterado ? apuracao.objRecebimentoDefinidoAlterado : null,
      };
      return objArmazenamento;
    },
    montarDataInicioFimApuracao(apuracao) {
      const datas = {
        inicio: '',
        fim: '',
      };

      if (!apuracao.datasParciais) {
        if (this.periodoIntegralApuracao(apuracao)) {
          datas.inicio = moment(apuracao.dataInicio, 'DD-MM-YYYY').format('YYYY-MM-DD');
          datas.fim = moment(apuracao.dataFim, 'DD-MM-YYYY').format('YYYY-MM-DD');
          return datas;
        }
        const datasAux = this.verificaRetornaDatasValidas(apuracao);
        if (datasAux.length === 1) {
          datas.inicio = formatFirstDayOfTheMonth(moment(datasAux[0], 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD');
          datas.fim = formatlastDayOfTheMonth(moment(datasAux[0], 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD');
          return datas;
        }
        datas.inicio = formatFirstDayOfTheMonth(moment(datasAux[0], 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD');
        datas.fim = formatlastDayOfTheMonth(moment(datasAux[datasAux.length - 1], 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD');
        return datas;
      }

      if (apuracao.datasParciais) {
        const datasAux = this.verificaRetornaDatasValidas(apuracao);
        if (datasAux.length === 1) {
          datas.inicio = formatFirstDayOfTheMonth(moment(datasAux[0], 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD');
          datas.fim = formatlastDayOfTheMonth(moment(datasAux[0], 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD');
          return datas;
        }
        datas.inicio = formatFirstDayOfTheMonth(moment(datasAux[0], 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD');
        datas.fim = formatlastDayOfTheMonth(moment(datasAux[datasAux.length - 1], 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD');
        return datas;
      }
      return datas;
    },
    indTodosMesesVigentes(apuracao) {
      const mesesParciais = this.arrayMesesParciais(apuracao),
        mesesApuracao = this.arrayMesesApuracao(apuracao),
        mesesValidos = mesesApuracao.filter((mes) => !mesesParciais.includes(mes)),
        mesesFiltro = this.arrayMesesFiltros(),
        mesesVigentes = mesesFiltro.filter((mes) => mesesValidos.includes(mes));
      return mesesVigentes.length === mesesValidos.length;
    },
    periodoIntegralApuracao(apuracao) {
      const p1 = moment(apuracao.dataInicio, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        p2 = moment(apuracao.dataFim, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        pf1 = moment(this.filtros.dataInicio, 'YYYY-MM').format('YYYY-MM-DD'),
        pf2 = moment(this.filtros.dataFim, 'YYYY-MM').format('YYYY-MM-DD');
      if (moment(p1).isBetween(pf1, pf2, 'month', '[]')
        && moment(p2).isBetween(pf1, pf2, 'month', '[]')) {
        return true;
      }
      return false;
    },
    // peridosRestantesApuracao(apuracao) {

    // },
    verificaRetornaDatasValidas(apuracao) {
      let mesesParciais = [];
      let mesesFiltro = [];
      let mesesApuracao = [];
      let mesesValidos = [];

      if (apuracao.datasParciais) {
        mesesParciais = this.arrayMesesParciais(apuracao);
        mesesFiltro = this.arrayMesesFiltros();
        /* retira todos os meses iguais nos dois arrays e se sobrar mesesValidos verifica os meses de vigencia da apuração, ex:
        * array1: [2023/01, 2023/02, 2023/04, 2023/05, 2023/06]
        * array2: [2023/01, 2023/02, 2023/03, 2023/04, 2023/05, 2023/06, 2023/07]
        * result: [2023/03, 2023/07]
        */
        mesesValidos = mesesFiltro.filter((mes) => !mesesParciais.includes(mes));
        if (mesesValidos && mesesValidos.length) {
          /* se possuir pelo menos 1 mes dentro do range de datas da apuração já é o suficiente para criar apuração parcial */
          mesesApuracao = this.arrayMesesApuracao(apuracao);
          let mesesReais = [];
          mesesReais = mesesValidos.filter((mes) => mesesApuracao.includes(mes));
          return mesesReais || [];
        }
      } else {
        let mesesReais = [];
        mesesFiltro = this.arrayMesesFiltros();
        mesesApuracao = this.arrayMesesApuracao(apuracao);
        mesesReais = mesesFiltro.filter((mes) => mesesApuracao.includes(mes));
        return mesesReais || [];
      }
      return [];
    },
    arrayMesesParciais(apuracao) {
      /*
        * constroi um array com todas as datas(ano/mes) de apurações parcias da apuração, ex:
        * apuracao parcial 1: 01/02/2023 --- 28/02/2023
        * apuracao parcial 2: 01/04/2023 --- 30/06/2023
        * result [2023/01, 2023/02, 2023/04, 2023/05, 2023/06]
        */
      const mesesParciais = [];
      apuracao.datasParciais.split('|').forEach((el1) => {
        const meses = el1.split(','),
          mesInicio = moment(meses[0], 'DD/MM/YYYY').startOf('month'),
          mesFim = moment(meses[1], 'DD/MM/YYYY').startOf('month');
        while (mesFim.diff(mesInicio, 'months') >= 0) {
          mesesParciais.push(mesInicio.format('YYYY-MM'));
          mesInicio.add(1, 'month');
        }
      });
      return mesesParciais;
    },
    arrayMesesFiltros() {
      /*
        * constroi um array com todas as datas(ano/mes) do filtro aplicado em tela, ex:
        * filtro 2023/01 --- 2023/07
        * result [2023/01, 2023/02, 2023/03, 2023/04, 2023/05, 2023/06, 2023/07]
        */
      const mesesFiltro = [],
        mesInicioFiltro = moment(moment(this.filtros.dataInicio, 'YYYY-MM').format('DD/MM/YYYY'), 'DD/MM/YYYY').startOf('month'),
        mesFimFiltro = moment(moment(this.filtros.dataFim, 'YYYY-MM').format('DD/MM/YYYY'), 'DD/MM/YYYY').startOf('month');
      while (mesFimFiltro.diff(mesInicioFiltro, 'months') >= 0) {
        mesesFiltro.push(mesInicioFiltro.format('YYYY-MM'));
        mesInicioFiltro.add(1, 'month');
      }
      return mesesFiltro;
    },
    arrayMesesApuracao(apuracao) {
      const mesesApuracao = [],
        mesInicio = moment(apuracao.dataInicio, 'DD/MM/YYYY').startOf('month'),
        mesFim = moment(apuracao.dataFim, 'DD/MM/YYYY').startOf('month');
      while (mesFim.diff(mesInicio, 'months') >= 0) {
        mesesApuracao.push(mesInicio.format('YYYY-MM'));
        mesInicio.add(1, 'month');
      }
      return mesesApuracao;
    },
    prepararParametros() {
      const filtros = { ...this.filtros, ...this.filtrosComplementares };
      if (this.apuracoesSelecionadas.length) {
        const idsApuracao = [];
        const apuracoesRecebimentoDefinidoAlterado = [];
        const apuracoesValorBaseAlterado = [];

        this.apuracoesSelecionadas.forEach((apuracao) => {
          if (apuracao.selecionado) {
            if (!filtros.idFornecedor) {
              filtros.idFornecedor = apuracao.idFornecedor;
            }

            idsApuracao.push(Number(apuracao.idApuracao));
            const dataInicio = moment(apuracao.dataInicio, 'DD/MM/YYYY').format('YYYY-MM-DD');
            const dataFim = moment(apuracao.dataFim, 'DD/MM/YYYY').format('YYYY-MM-DD');

            if (apuracao.objValorBaseAlterado
                && (Object.values(apuracao.objValorBaseAlterado).length !== 0)) {
              const valoresBase = {
                id_apuracao: Number(apuracao.objValorBaseAlterado.idApuracao),
                vlr_anterior: apuracao.objValorBaseAlterado.vlrAnterior,
                vlr_alterado: apuracao.objValorBaseAlterado.vlrAlterado,
                justificativa: apuracao.objValorBaseAlterado.justificativa,
                dta_inicio_periodo: dataInicio,
                dta_fim_periodo: dataFim,
              };
              apuracoesValorBaseAlterado.push(valoresBase);
            }

            if (apuracao.objRecebimentoDefinidoAlterado
                && (Object.values(apuracao.objRecebimentoDefinidoAlterado).length !== 0)) {
              const valoresRecebimentoDefinido = {
                id_apuracao: Number(apuracao.objRecebimentoDefinidoAlterado.idApuracao),
                vlr_anterior: apuracao.objRecebimentoDefinidoAlterado.vlrAnterior,
                vlr_alterado: apuracao.objRecebimentoDefinidoAlterado.vlrAlterado,
                justificativa: apuracao.objRecebimentoDefinidoAlterado.justificativa,
                dta_inicio_periodo: dataInicio,
                dta_fim_periodo: dataFim,
              };
              apuracoesRecebimentoDefinidoAlterado.push(valoresRecebimentoDefinido);
            }
          }
        });
        filtros.apuracoesSelecionadas = idsApuracao;
        filtros.apuracoesFornecedores = [];
        filtros.apuracoesRecebimentoDefinidoAlterado = apuracoesRecebimentoDefinidoAlterado.length ? apuracoesRecebimentoDefinidoAlterado : null;
        filtros.apuracoesValorBaseAlterado = apuracoesValorBaseAlterado.length ? apuracoesValorBaseAlterado : null;
      }
      filtros.idTipoAcao = [filtros.tipoAcao];
      filtros.idGrupoFornecedor = filtros.id_grupo_fornecedor;
      filtros.dtaInicioApuracao = moment(filtros.dataInicio, 'YYYY-MM').format('YYYY-MM-DD');
      filtros.dtaFimApuracao = formatlastDayOfTheMonth(moment(filtros.dataFim, 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD');
      filtros.status = ['AGUARDANDO_APURACAO'];
      filtros.idFornecedor = idFornecedorPagadorDefinido;

      delete filtros.tipoAcao;
      delete filtros.id_grupo_fornecedor;
      delete filtros.dataInicio;
      delete filtros.dataFim;
      delete filtros.grupo_fornecedor;
      delete filtros.categoria;
      delete filtros.numeroPagina;
      delete filtros.tamanhoPagina;

      return filtros;
    },
    apuracaoPossuiInconsistencia(apuracao) {
      return (apuracao.indJustificativa && !apuracao.justificativaAlterarRecebimento)
      || (typeof apuracao.vlrRealizado === 'undefined' || apuracao.vlrRealizado === '')
      || !apuracao.possuiNota || !apuracao.existeAtingimento;
    },
    apuracaoPossuiInconsistenciaApuracoes(apuracao) {
      return (apuracao.vlrNdGerado === 0 && apuracao.recebimentoDefinido <= 0)
      || ((apuracao.periodicidade !== 'MENSAL' && !apuracao.possuiNota && apuracao.vlrNdGerado === 0))
      || (apuracao.periodicidade !== 'MENSAL' && apuracao.datasParciais && !this.verificaRetornaDatasValidas(apuracao).length)
      || (apuracao.vlrNdGerado > 0 && apuracao.recebimentoDefinido === 0 && (!this.periodoIntegralApuracao(apuracao)
          || !this.indTodosMesesVigentes(apuracao)));
    },
    setaIdContratoTipo(idContratoTipo) {
      this.idContratoTipo = idContratoTipo;
    },
    alterarIsEditandoValorBase(valor) {
      this.isEditandoValorBase = valor;
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
    this.buscarTiposUso();
  },
};
</script>
<style lang="scss">
.ApuracaoContrato_breadcrumbs-item_disabled {
  color: grey;
  pointer-events: none;
}
.ApuracaoContrato_breadcrumbs-item {
  color: grey;
  cursor: pointer;
}
.ApuracaoContrato_breadcrumbs-item:hover {
  color: #3f3f3f;
}
</style>
